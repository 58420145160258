let menu = document.querySelector('.vsp-c-navigation__hamburger-btn');
let menuNavbar = document.querySelector('.vsp-c-navigation');

menu.addEventListener('click', function(event) {
    toggleActiveMenu();
    event.preventDefault();
    event.stopPropagation();
});
window.addEventListener('click', function(e){   
    if (!menuNavbar.contains(e.target)) {
        menuNavbar.className = menuNavbar.className.replace('active', '').trim();
    }
});

function toggleActiveMenu() {
  let isActive = menuNavbar.className.indexOf('active') > -1;
  menuNavbar.className = isActive
    ? menuNavbar.className.replace('active', '').trim()
    : (menuNavbar.className + ' active');
}


if( isIE() && isIE() < 10 || isMozzila() && isMozzila() < 11 ) {
  window.location.href = "old-browser";
}

function isIE () {
  var myNav = navigator.userAgent.toLowerCase();
  return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
}

function isMozzila () {
  var myNav = navigator.userAgent.toLowerCase();
  return  (myNav.indexOf('firefox') != -1) ? parseInt(myNav.split('firefox/')[1]) : false;
}
